import * as Sentry from '@sentry/nuxt'

Sentry.init({
  dsn: useRuntimeConfig().public.sentry.dsn,
  debug: false,
  environment: useRuntimeConfig().public.sentry.environment,
  release: useRuntimeConfig().public.release,
  tracesSampleRate: useRuntimeConfig().public.tracesSampleRate,

  beforeSend: (event: any, _hint: any) => {
    const excludedFilenames = [
      'abtasty',
      'axeptio', // Cookie consent
      'clarity',
      'facebook',
      'google', // Google Ads
      'gtm.js', // Google Tag Manager
      'mouseflow', // User session recording
      'sstdata', // Tracking
      'wonderpush.min.js',
    ]

    // filter out errors brought by stuff loaded from 3rd party scripts
    if (
      event.exception?.values?.[0]?.stacktrace?.frames?.some((f: any) =>
        excludedFilenames.some((filename) => f.filename?.includes(filename))
      )
    ) {
      return null
    }

    return event
  },
  beforeSendTransaction: (event: any) => {
    const excludedOrigins = [
      'abtasty.com',
      'axeptio.eu',
      'clarity.ms',
      'facebook.com',
      'google.com',
      'mouseflow.com',
    ]

    // Vérifie si la transaction concerne un domaine tiers
    if (
      event.transaction &&
      excludedOrigins.some((origin) => event.transaction.includes(origin))
    ) {
      return null
    }

    return event
  },
  ignoreErrors: [
    'abtasty',
    'axeptio',
    'clarity',
    'facebook',
    'google',
    'mouseflow',
    'sstdata',
  ],
})
